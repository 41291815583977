import React from 'react';
import PropTypes from 'prop-types';
import { apps, icons, response as responseContent } from '../config'; // Rename the imported response

// Components
import Program from './program';

// Helper components or functions
const ResponseContent = () => (
  <>
    {responseContent.content}
    <br />
    To: CYPHERPUNKS CYPHERPUNKSTOAD.COM
    <br />
    Subject: RE:Crypto trading cards.
    <br />
    From: Ronin @CompuServe.COM
    <br />
    Date:
    {' '}
    <del>
      23 Jan 93 13:48:02 EST
    </del>
    {' '}
    <br />
    ========================================
    <br />
    <br />
    It’s great that the idea we had while
    extremely stoned, playing D&D last weekend,
    has all of a sudden become all anyone in this
    group wants to talk about.
    Honestly, I shouldn’t be surprised.
    <br />
    <br />
    Talked to a couple of the punks and they’re
    on board. So… I guess I’m in. I do still
    question whether or not anyone will give a shit.
    Honestly Hal, the only thing anyone seems to
    care about is how the
    {' '}
    <a href="https://youtu.be/8FjH7UQbXbo" style={{ color: 'inherit', textDecoration: 'none' }}>
      ŧəɔђηөᴌᴓϭУ
    </a>
    {' '}
    can make them money. Nobody has even thought about how to have
    fun with it. You truly are a visionary. Or just an
    over aged child with crazy ideas that will end up
    costing us all our free time, and probably a
    decent amount of cash.
    <br />
    <br />
    Guess we’ll see. Tris & Synon are working
    on the first iteration of code. The way you
    described the art was pretty unique. So I
    reached out to a friend to see if he could
    come up with something cool for the beta set.
    Will keep you updated.
    <br />
    <br />
    BTW… anybody heard from Zero lately?
    Last reply to a thread was almost 2 weeks ago?
    <br />
    <br />
    Anyway, I’m excited to get into this and play.
    <br />
    <br />
    -Ronin
    <br />
    {/* Other content here */}
  </>
);

const Response = ({ // Rename the component to Response
  updateActiveApp,
  closeApp,
  updateStartbar,
  openApps,
  minimizedApps,
  currentlyActiveApp,
  previouslyActiveApp,
}) => (
  <Program
    programName={apps.response}
    programIcon={icons[apps.response.toLowerCase()].url}
    contentEditable
    updateActiveApp={updateActiveApp}
    updateStartbar={updateStartbar}
    closeApp={closeApp}
    openApps={openApps}
    minimizedApps={minimizedApps}
    currentlyActiveApp={currentlyActiveApp}
    previouslyActiveApp={previouslyActiveApp}
  >
    <ResponseContent />
  </Program>
);

Response.defaultProps = {
  updateActiveApp: () => {},
  closeApp: () => {},
  updateStartbar: () => {},
  openApps: [],
  minimizedApps: [],
  currentlyActiveApp: '',
  previouslyActiveApp: '',
};

Response.propTypes = {
  updateActiveApp: PropTypes.func,
  closeApp: PropTypes.func,
  updateStartbar: PropTypes.func,
  openApps: PropTypes.array,
  minimizedApps: PropTypes.array,
  currentlyActiveApp: PropTypes.string,
  previouslyActiveApp: PropTypes.string,
};

export default Response;
