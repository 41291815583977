import React from 'react';
import PropTypes from 'prop-types';
import { apps, icons, contact } from '../config';

// Components
import Program from './program';

// Helper components or functions
const ContactContent = () => (
  <>
    {contact.content}
    <br />
    <br />
    To: CYPHERPUNKS CYPHERPUNKSTOAD.COM
    <br />
    Subject: Crypto trading cards.
    <br />
    From: Hal @CompuServe.COM
    <br />
    Date: 17 Jan 93 13:48:02 EST

    <br />
    ==========================================
    <br />
    Giving a little more thought to the idea of buying and selling digital cash, I thought of a way
    to present it. We’re buying and selling “cryptographic trading cards”. Fans of cryptography
    will love these fascinating examples of the cryptographic arts. Notice the fine way the bit
    patterns fit together a mix of one-way functions and digital signatures, along with random
    blinding. What a perfect conversation piece to be treasured and shown to your friends
    and family.
    <br />
    <br />
    Plus, your friends will undoubtedly love these cryptographic trading cards just as much.
    They’ll be eager to trade for them. Collect a whole set! They come in all kinds of
    varieties, from the common 1’s, to the rarer 50’s, all the way up to the seldom-seen
    1000’s. Hours of fun can be had for all.
    <br />
    <br />
    Your friendly cryptographic trading card dealer wants to join the fun, too.
    He’ll be as interested in buying your trading cards back as in selling them.
    <br />
    <br />
    Try this fascinating and timely new hobby today!
    <br />
    <br />
    Hal
    <br />
    <br />
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-
    &apos;^^&apos;-.__.-&apos;^^&apos;-._.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.____.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^
    ^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-
    &apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-..__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-._
    .__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__
    __.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.
    _.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    .-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;
    -&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    -.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^
    ^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;-.__.-
    &apos;^^&apos;-.__.-&apos;^^&apos;-.__.-&apos;^^&apos;
    <br />
    <br />
    <br />
    Well aren’t you curious? Or maybe, you’re just L33T.
    Either way, be among the first 500 through the portal
    and claim your role for allowlist. https://discord.gg/exYJUXUaqV
    <br />
    <br />
  </>
);

const Contact = ({
  updateActiveApp,
  closeApp,
  updateStartbar,
  openApps,
  minimizedApps,
  currentlyActiveApp,
  previouslyActiveApp,
}) => (
  <Program
    programName={apps.contact}
    programIcon={icons[apps.contact.toLowerCase()].url}
    contentEditable
    updateActiveApp={updateActiveApp}
    updateStartbar={updateStartbar}
    closeApp={closeApp}
    openApps={openApps}
    minimizedApps={minimizedApps}
    currentlyActiveApp={currentlyActiveApp}
    previouslyActiveApp={previouslyActiveApp}
  >
    <ContactContent />
  </Program>
);

Contact.defaultProps = {
  updateActiveApp: () => {},
  closeApp: () => {},
  updateStartbar: () => {},
  openApps: [],
  minimizedApps: [],
  currentlyActiveApp: '',
  previouslyActiveApp: '',
};

Contact.propTypes = {
  updateActiveApp: PropTypes.func,
  closeApp: PropTypes.func,
  updateStartbar: PropTypes.func,
  openApps: PropTypes.array,
  minimizedApps: PropTypes.array,
  currentlyActiveApp: PropTypes.string,
  previouslyActiveApp: PropTypes.string,
};

export default Contact;
