// config.js

// Import images
import email from './images/email.svg';
// import briefcase from './images/briefcase.svg';
import settings from './images/settings.svg';
import moon from './images/moon.svg';
import backgroundClassic from './images/backgrounds/classic.jpg';
import backgroundXp from './images/backgrounds/xp.jpg';
import backgroundClouds from './images/backgrounds/clouds.jpg';
import backgroundLogo from './images/backgrounds/logo.jpg';
import backgroundVaporwave from './images/backgrounds/vaporwave.jpg';
import backgroundSocial from './images/backgrounds/social.jpg';
import windowsUpdateIcon from './images/updateimage.ico';

// Define your apps
export const apps = {
  // messenger: 'Chat',
  // work: 'Work',
  contact: 'Contact',
  response: 'Response',
  shutdown: 'Shutdown',
  settings: 'Settings',
  updateprocess: 'Windows Update',
};

// Define your icons
export const icons = {
  // chat: {
  //   url: bot,
  //   alt: 'Icon of bot',
  // },
  // work: {
  //   url: briefcase,
  //   alt: 'Icon of briefcase',
  // },
  contact: {
    url: email,
    alt: 'Icon of email',
  },
  response: {
    url: email,
    alt: 'Icon of email',
  },
  shutdown: {
    url: moon,
    alt: 'Icon of moon',
  },
  settings: {
    url: settings,
    alt: 'Icon of settings',
  },
  updateprocess: {
    url: windowsUpdateIcon, // Ensure you import windowsUpdateIcon
    alt: 'Windows Update',
  },
};

// Other exports
export const resumeLink = 'https://linkedin.com';

export const contact = {
  content: 'Crypto trading cards',
  // emailLink: 'mailto:trislit@gmail.com',
  // linkedin: 'https://linkedin.com/',
  // github: 'http://github.com/ronin',
};

export const response = {
  content: 'RE: Crypto trading cards',
  github: 'http://github.com/',
};

// System settings
export const systemSettings = {
  background: [
    {
      name: 'Classic',
      url: backgroundClassic,
    },
    {
      name: 'XP',
      url: backgroundXp,
    },
    {
      name: 'Clouds',
      url: backgroundClouds,
    },
    {
      name: 'Logo',
      url: backgroundLogo,
    },
    {
      name: 'Vaporwave',
      url: backgroundVaporwave,
    },
    {
      name: 'Social',
      url: backgroundSocial,
    },
  ],
  theme: ['Light', 'Dark'],
};

export const API = 'https://portfolio-chatbot.herokuapp.com/api/message';
