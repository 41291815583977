import React, { useEffect, useState } from 'react';

const UpdateProcess = () => {
  const [updateProgress, setUpdateProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setUpdateProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          // Redirect to new desktop
          window.location.href = 'https://ogdesktop.l33tlabs.io'; // Replace with your new desktop URL
          return 100;
        }
        return prevProgress + 5; // Update progress by 5% every interval
      });
    }, 500); // Update every half-second

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="update-process">
      <p>
        Installing Updates...
        {updateProgress}
        %
      </p>
      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${updateProgress}%` }}
        />
      </div>
    </div>
  );
};

export default UpdateProcess;
